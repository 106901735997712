import React, { useEffect, useState } from 'react'
import api from '../api/api'
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { GrView } from "react-icons/gr";

const AdminDashboardBankVerify = () => {
    const [bankVerify, setBankVerify] = useState([])
    const [verify, setVerify] = useState([])

    useEffect(() => {
        api.get("/affiliate/getAllAffiliates")
            .then((res) => {

                setBankVerify(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
    }, [])
    return (
        <>
            <div className='dashboard-container'>
                <h1>Bank Verification</h1>
                <div className='search-container'>
                    <input
                        type="text"
                        placeholder='Search User'

                    />
                    <button>Search</button>
                </div>
                <h2>Bank Verification</h2>

                <table className='adminuser-table'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                           
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bankVerify.map((bank) => (
                            <tr key={bank.id}>
                                <td>{bank.name}</td>
                                <td>{bank.email}</td>
                                <td>{bank.phone}</td>
                               

                                <td>
                                    <FaEdit  style={{ fontSize: "18px", cursor: 'pointer' }} />
                                    <MdDelete  style={{ fontSize: "18px", cursor: 'pointer' }} />
                                    <GrView  style={{ fontSize: "18px", cursor: 'pointer' }} />
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>
        </>
    )
}

export default AdminDashboardBankVerify