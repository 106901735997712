import { useEffect, useState } from 'react';
import api, { BASE_URL } from '../api/api'; 
import { toast } from 'react-hot-toast';
import { IoIosClose } from "react-icons/io";

const useSaleAlert = () => {
  const [saleAlert, setSaleAlert] = useState(null);
  const [loading, setLoading] = useState(true);

  const showCustomToast = (alertData) => {
    const imageUrl = `${BASE_URL}/images/${alertData.image_url}`;

    toast.custom((t) => (
      <div className='notificationmain_toast'>
        <div style={{ display: "flex", alignItems: "center" }} className="notificationmain_toast_image-container">
          <img
            className="notificationmain_toast_image"
            src={imageUrl}
            alt={alertData.name}
          />
        </div>
        {alertData && (
          <div style={{ marginLeft: "12px", flex: "1" }}>
            <p style={{ margin: 0, fontWeight: "bold" }}>{alertData.name} <span style={{ margin: 0, fontWeight: "normal" }}>has purchased</span></p>
            <p style={{ color: "gray" }}>{`${alertData.product_name} - ${alertData.time}`}</p>
          </div>
        )}
        <div>
          <button className="notificationmain_toast_btn" onClick={() => toast.dismiss(t.id)}>
            <IoIosClose size={25} className="notificationmain_toast-icon" />
          </button>
        </div>
      </div>
    ), { duration: 4000 });
  };

  const fetchSaleAlert = () => {
    api.get('/notification/sale-alert')
      .then((res) => {
        if (res.data && res.data.success) {
          setSaleAlert(res.data.data);
          showCustomToast(res.data.data); 
        } else {
          console.warn("No sale alert found in response:", res.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchSaleAlert(); 
    const intervalId = setInterval(fetchSaleAlert, 6000); 

    return () => clearInterval(intervalId); 
  }, []);

  return { saleAlert, loading };
};

export default useSaleAlert;
