import React from 'react';
import { Formik } from 'formik';
import api from '../../../src/api/api';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useAuth } from './AuthContext'; 
import { Toaster} from 'react-hot-toast';
import useSaleAlert from '../../pages/ShowNotification';

const Login = () => {
    const { saleAlert, loading } = useSaleAlert(); 
    const navigate = useNavigate();
    const { login } = useAuth();

    return (
        <div className="login-container" style={{ marginTop: "140px" }}>
            <h1>Please Login!</h1>
            <Formik
                initialValues={{ email: '', password: '' }}
                validate={values => {
                    const errors = {};
                    if (!values.email) {
                        errors.email = 'Required';
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                        errors.email = 'Invalid email address';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    api.post(`/auth/signIn`, {
                        email: values.email,
                        password: values.password,
                    })
                    .then((response) => {                    
                        const user = response.data.data;

                     Cookies.set('role', user.role, { secure: true, sameSite: 'Strict' });
                     

                        if (user.role !== 1) {
                            Cookies.set('rolee', user.rolee, { secure: true, sameSite: 'Strict' });
                            Cookies.set('id', user.userid, { secure: true, sameSite: 'Strict' });
                        } else {   
                            Cookies.remove('rolee');
                            Cookies.remove('id');
                        }
                        notification.success({ message: 'Sign-in successful!'});
                        login();
                        if (user.role === 1) {
                            navigate("/dashboard");
                        } else {
                            navigate("/my-account");
                            window.location.reload();
                        }
                    })
                    .catch((error) => {
                        if (error.response && error.response.data && error.response.data.message) {
                            notification.error({ message: error.response.data.message });
                        } else {
                            notification.error({ message: 'Login failed. Please try again.' });
                        }
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <form className="login-form" onSubmit={handleSubmit}>
                        <input
                            type="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            placeholder="Email"
                        />
                        {errors.email && touched.email && <div className="error-message">{errors.email}</div>}
                        <input
                            type="password"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            placeholder="Password"
                        />
                        {errors.password && touched.password && <div className="error-message">{errors.password}</div>}
                        <button type="submit" disabled={isSubmitting}>
                            Login
                        </button>
                    </form>
                )}
            </Formik>
            <Toaster position="bottom-left" reverseOrder={false} /> 
        </div>
    );
};

export default Login;
