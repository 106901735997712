import { useEffect, useState } from 'react';
import api from '../../api/api';
import Cookies from 'js-cookie';
import Layout from '../Layout/Layout';
import PageTitle from '../pageTitle/PageTitle';
import AffiliateBankDetails from './AffiliateBankDetails';
import ShowBankData from './ShowBankData';
import { MdVerifiedUser } from "react-icons/md";


function AffiliateDetails() {
    const [commission, setCommission] = useState(null);
    const [commissionPaid, setCommissionPaid] = useState([]);
    const [commissionUnpaid, setCommissionUnpaid] = useState([]);
    const [filter, setFilter] = useState('paid');
    const [showBankDetails, setShowBankDetails] = useState(false);
    const [showBankData, setShowBankData] = useState(false); 

    const affiliateId = Cookies.get('affiliateId');
    const name = Cookies.get('name');
    const bankVerify = Cookies.get("bankVerify");

    const code = Cookies.get('code');
    const commissionvalue = Cookies.get('commissionvalue');
    const discount = Cookies.get("discount");


    useEffect(() => {
        const fetchCommission = async () => {
            try {
                const response = await api.get(`affiliate/commission/${affiliateId}`);
                const { data } = response.data;
                setCommission(data);
            } catch (error) {
                console.error('Error fetching commission data:', error);
            }
        };

        if (affiliateId) {
            fetchCommission();
        }
    }, [affiliateId]);

    useEffect(() => {
        const fetchPaidCommission = async () => {
            try {
                const response = await api.get(`affiliate/transactions/paid/${affiliateId}`);
                const { data } = response.data;
                setCommissionPaid(data);
            } catch (error){
                console.error('Error fetching paid commission data:', error);
            }
        };
       
        const fetchUnPaidCommission = async () => {
            try {
                const response = await api.get(`affiliate/transactions/pending/${affiliateId}`);
                const { data } = response.data;
                setCommissionUnpaid(data);
            } catch (error) {
                console.error('Error fetching unpaid commission data:', error);
            }
        };

        if (affiliateId) {
            fetchPaidCommission();
            fetchUnPaidCommission();
        }
    }, [affiliateId]);

    const handleFilterChange = (newFilter) => {
        setFilter(newFilter);
        setShowBankDetails(false);
        setShowBankData(false); 
    };

    const handleAddBankDetailsClick = () => {
        setShowBankDetails(true);
        setShowBankData(false); 
    };

    const handleShowBankDataClick = () => {
        setShowBankData(true); 
        setShowBankDetails(false); 
    };
    

    const filteredCommissions = filter === 'paid' ? commissionPaid : commissionUnpaid;
    return (
        <Layout>
            <PageTitle gredTitle="Affiliate Dashboard" />
            <div className="affiliate-details-container">
                <div className="affiliate-header">
                    <h2>Welcome {name},</h2>
                    <p className="status-text">
                        <span
                            className={filter === 'paid' ? 'active' : ''}
                            onClick={() => handleFilterChange('paid')}
                            style={{
                                cursor: 'pointer',
                                marginRight: '10px',
                                backgroundColor: filter === 'paid' ? '#4CAF50' : '',
                                color: filter === 'paid' ? 'white' : '',
                                padding: '5px 10px',
                            }}
                        >
                            Paid
                        </span>
                        <span
                            className={filter === 'unpaid' ? 'active' : ''}
                            onClick={() => handleFilterChange('unpaid')}
                            style={{
                                cursor: 'pointer',
                                backgroundColor: filter === 'unpaid' ? '#ff9007' : '',
                                color: filter === 'unpaid' ? 'white' : '',
                                padding: '5px 10px',
                            }}
                        >
                            Unpaid
                        </span>

                        {bankVerify === 'true' ? (
                            <span>
                                <a href="#" onClick={handleShowBankDataClick}
                                 className='handleShowBankDataClick'
                                >
                                    Show Bank Info <MdVerifiedUser />
                                </a>
                            </span>
                        ) : (
                            <span>
                                <a href="#" onClick={handleAddBankDetailsClick}>
                                    Add Bank Details
                                </a>
                            </span>
                        )}
                    </p>
                </div>
                   
                   {/* **************** */}
                   <table className="affiliate-table">
                            <thead>
                                <tr>
                                    <th>Your Coupon Discount</th>
                                    <th>Your Commission Value</th>
                                    <th> Your Coupon Code</th>
                                </tr>
                            </thead>
                            <tbody>
                               
                                        <tr>
                                            <td>{ discount || 'N/A'}</td>
                                            <td>{commissionvalue|| 'N/A'}</td>
                                            <td>{code || 'N/A'}</td>
                                        </tr>
                                        
                                   
                            </tbody>
                        </table>

                   {/* ********************* */}

                {showBankDetails ? (
                    <AffiliateBankDetails affiliateId={affiliateId} />
                ) : showBankData ? (
                    <ShowBankData affiliateId={affiliateId} />
                ) : (
                    <>
                        <table className="affiliate-table">
                            <thead>
                                <tr>
                                    <th>Total Paid Out Commission</th>
                                    <th>Earn More to Release</th>
                                    <th>Non Paid Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {commission ? (
                                    <>
                                        <tr>
                                            <td>{commission.totalPaidAmount || 'N/A'}</td>
                                            <td>{commission.remainingToNextSlab || 'N/A'}</td>
                                            <td>{commission.totalUnpaidCommission || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Message</strong></td>
                                            <td colSpan="3">{commission.slabMessage || 'N/A'}</td>
                                        </tr>
                                    </>
                                ) : (
                                    <tr>
                                        <td colSpan="4">No commission data available</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        <table className="affiliate-table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Email of Order</th>
                                    <th>Paid</th>
                                    <th>Commission Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredCommissions.length > 0 ? (
                                    filteredCommissions.map((elem, index) => (
                                        <tr key={index}>
                                            <td>
                                                {filter === 'paid'
                                                    ? (elem.updatedAt ? new Date(elem.updatedAt).toLocaleDateString() : "N/A")
                                                    : (elem.createdAt ? new Date(elem.createdAt).toLocaleDateString() : "N/A")}
                                            </td>
                                            <td>{elem.Order_Email || 'N/A'}</td>
                                            <td>{elem.Paid || 'N/A'}</td>
                                            <td>{elem.Amount || 'N/A'}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="4">No {filter} commissions available.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </>
                )}
            </div>
        </Layout>
    );
}

export default AffiliateDetails;
