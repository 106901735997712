import React, { useState, useEffect } from 'react';
import api from '../api/api';
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { GrView } from "react-icons/gr";


const AffiliateDetailsPage = () => {
   
    const [affiliates, setAffiliates] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [editingAffiliate, setEditingAffiliate] = useState(null);
    const [viewingAffiliate, setViewingAffiliate] = useState(null);
    const [updatedData, setUpdatedData] = useState({ name: '', email: '', phone: '', address: '' });
    const [commissionSlab, setCommissionSlab] = useState([]);
    const [isShowDetails, setIsShowDetails] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isShowPaid, setIsShowPaid] = useState(false);
    const [isShowPending, setIsShowPending] = useState(false); 
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [affiliateID, setAffiliateID] = useState(null);
    const [paidTransaction, setPaidTransaction] = useState([]);
    const [pendingTransaction, setPendingTransaction] = useState([]);
 
    useEffect(() => {
        const fetchPaidTransaction = async () => {
            if (affiliateID) {
                try {
                    const response = await api.get(`affiliate/transactions/paid/${affiliateID}`);
                    setPaidTransaction(response.data.data);
                    setIsShowPaid(false);
                } catch (error) {
                   
                }
            }
        };     
        fetchPaidTransaction();
    }, [affiliateID]);

    const fetchAffiliates = async (page = 1) => {
        try {
            const { data } = await api.get(`/affiliate/getAllAffiliates`, { params: { page, limit: itemsPerPage } });
            if (data && data.data) {
                setAffiliates(data.data || []);
                setCurrentPage(data.data.currentPage);
                setTotalPages(data.data.totalPages);
                setAffiliateID(data.data[0].id);
            }
        } catch (error) {
            console.error('Error fetching affiliates:', error);
        }
    };

    useEffect(() => {
        fetchAffiliates(currentPage);
    }, [currentPage, itemsPerPage]);

    const fetchCommissionSlab = async () => {
        try {
            const { data } = await api.get(`/affiliate/calculateCommissionSlabForAllAffiliates`);
            if (data && data.data) {
                setCommissionSlab(data.data || []);
            }
        } catch (error) {
            console.error('Error fetching commission slab:', error);
        }
    };

    useEffect(() => {
        fetchCommissionSlab();
    }, []);

    const getAffiliateSlab = (affiliateId) => {
        return commissionSlab.find(slab => slab.affiliateId === affiliateId);
    };

    const handleSearch = async () => {
        if (searchQuery.trim()) {
            try {
                const { data } = await api.get(`/affiliate/searchAffiliates`, { params: { query: searchQuery } });
                if (data && data.data) {
                    setAffiliates(data.data || []);
                    setTotalPages(1);
                }
            } catch (error) {
                console.error('Error searching affiliates:', error);
            }
        } else {
            fetchAffiliates(currentPage);
        }
    };

    const handleEdit = async () => {
        try {
            await api.put(`/affiliate/editAffiliate/${editingAffiliate.id}`, updatedData);
            setAffiliates((prev) => prev.map((affiliate) =>
                affiliate.id === editingAffiliate.id ? { ...affiliate, ...updatedData } : affiliate
            ));
            setIsEditing(false);
        } catch (error) {
            console.error('Error editing affiliate:', error);
        }
    };

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm("Would you like to delete? Yes or No");
        if (confirmDelete) {
            try {
                await api.delete(`/affiliate/deleteAffiliate/${id}`);
                setAffiliates((prev) => prev.filter((affiliate) => affiliate.id !== id));
            } catch (error) {
                console.error('Error deleting affiliate:', error);
            }
        }
    };

    const handleView = (affiliate) => {
        setViewingAffiliate(affiliate);
        setIsShowDetails(true);
        const slabData = commissionSlab.find(slab => slab.affiliateId === affiliate.id);
        setUpdatedData({
            currentSlab: slabData ? slabData.currentSlab : 'N/A',
            totalUnpaidCommission: slabData ? slabData.totalUnpaidCommission : '0'
        });
    };

    const handleEditAffiliate = (affiliate) => {
        setEditingAffiliate(affiliate);
        setIsEditing(true);
        setUpdatedData({
            name: affiliate.name,
            email: affiliate.email,
            phone: affiliate.phone,
            address: affiliate.address
        });
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
        setEditingAffiliate(null);
    };

    const handleChangePage = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const ViewPaidBothTransaction = () => {
        setIsShowPaid(true);
        setIsShowDetails(false);
        setIsShowPending(false);
    };

    const ViewPendingTransaction = async () => {
        if (affiliateID) {
            try {
                const response = await api.get(`affiliate/transactions/pending/${affiliateID}`);

                setPendingTransaction(response.data.data);
                setIsShowPending(true);
                setIsShowDetails(false);
            } catch (error) {
                console.error('Error fetching pending transactions:', error);
            }
        }
    };
    return (
        <div className="dashboard-container">
            {!isShowDetails && !isEditing && !isShowPaid && !isShowPending ? (
                <>
                    <h1>Affiliate Dashboard</h1>
                    <div className="search-container">
                        <input
                            type="text"
                            placeholder="Search affiliates"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <button onClick={handleSearch}>Search</button>
                    </div>
                    <table className="affiliates-table">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Address</th>
                                <th>Slab</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {affiliates.map((affiliate) => (
                                <tr key={affiliate.id}>
                                    <td>{affiliate.id}</td>
                                    <td>{affiliate.name}</td>
                                    <td>{affiliate.email}</td>
                                    <td>{affiliate.phone}</td>
                                    <td>{affiliate.address}</td>
                                    <td>{getAffiliateSlab(affiliate.id)?.currentSlab || 'N/A'}</td>
                                    <td>
                                        <button onClick={() => handleEditAffiliate(affiliate)}><FaEdit /></button>
                                        <button onClick={() => handleDelete(affiliate.id)}><MdDelete /></button>
                                        <button onClick={() => handleView(affiliate)}><GrView /></button>
                                        <button onClick={ViewPendingTransaction}><GrView /></button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className="pagination-controls">
                        <button onClick={() => handleChangePage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <button onClick={() => handleChangePage(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
                        <select value={itemsPerPage} onChange={(e) => setItemsPerPage(Number(e.target.value))}
                            className='dropdowndashboard'
                        >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                        </select>
                    </div>
                </>
            ) : isShowDetails && !isShowPaid && !isShowPending ? (
                <div className='dashboardAffiliateView'>
                    {viewingAffiliate && (
                        <>
                            <div className='dashboardAffiliateViewInner'>
                                <h1>Affiliate Details</h1>
                                <p>Name: {viewingAffiliate.name}</p>
                                <p>Email: {viewingAffiliate.email}</p>
                                <p>Phone: {viewingAffiliate.phone}</p>
                                <p>Address: {viewingAffiliate.address}</p>
                                <button
                                  className='ViewPaidTransaction_css'
                                 onClick={ViewPaidBothTransaction}>View Paid Transactions</button>
                                <button onClick={ViewPendingTransaction}
                                className='ViewPaidTransaction_css'
                                >View Pending Transactions</button>
                                <button
                                className='dashboardButtonBack'
                                 onClick={()=>setIsShowDetails(false)}>Back</button>
                            </div>
                        </>
                    )}
                </div>
            ) : isShowPaid ? (
                <>
                    <div className='ViewPaidTrasactionTable'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Order Email</th>
                                    <th>Amount</th>
                                    <th>Paid</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                 
                                {paidTransaction.length > 0 && paidTransaction.map((transaction) => (
                                    <tr key={transaction.id}>
                                        <td>{transaction.Order_Email}</td>
                                        <td>{transaction.Amount}</td>
                                        <td>{transaction.Paid}</td>
                                        <td>{transaction.updatedAt}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <button className='dashboardButtonBack'
                        onClick={() => { setIsShowPaid(false); setIsShowDetails(true); }}>Back</button>
                </>
            ) : isShowPending ? (
                <>
                    <div className='ViewPaidTrasactionTable'>
                        <h1>Pending Transactions</h1>
                        <table>
                            <thead>
                                <th>Order Email</th>
                                <th>Amount</th>
                                <th>Paid</th>
                                <th>Date</th>
                            </thead>
                            <tbody>
                                {pendingTransaction.map((transaction) => (
                                    <tr key={transaction.id}>
                                        <td>{transaction.Order_Email}</td>
                                        <td>{transaction.Amount}</td>
                                        <td>{transaction.Paid}</td>
                                        <td>{transaction.createdAt}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <button className='dashboardButtonBack'
                        onClick={() => { setIsShowPending(false); setIsShowDetails(true);}}>Back</button>
                </>
            ) : isEditing && (
                <div className='dashboardAffiliateEditInner'>
                    <h2>Edit Affiliate</h2>
                    <label htmlFor="name">Name:</label>
                    <input
                        type="text"
                        id='name'
                        value={updatedData.name}
                        onChange={(e) => setUpdatedData({ ...updatedData, name: e.target.value })}
                    />
                    <br /> <br />
                    <label htmlFor="email">Email:</label>
                    <input
                     id='email'
                        type="text"
                        value={updatedData.email}
                        onChange={(e) => setUpdatedData({ ...updatedData, email: e.target.value })}
                    />
                    <br /> <br />
                    <label htmlFor="phone">Phone:</label>
                    <input
                       id='phone'
                        type="text"
                        value={updatedData.phone}
                        onChange={(e) => setUpdatedData({ ...updatedData, phone: e.target.value })}
                    />
                    <br /> <br />
                    <label htmlFor="date">Date:</label>
                    
                    <input
                    id='date'
                        type="text"
                        value={updatedData.address}
                        onChange={(e) => setUpdatedData({ ...updatedData, address: e.target.value })}
                    />
                    <br /> <br />
                    <button onClick={handleEdit}
                    className='dashboardAffiliateEditInner_save'
                    >Save</button>
                    <button
                    className='dashboardAffiliateEditInner_cancel'
                     onClick={handleCancelEdit}>Cancel</button>
                </div>
            )}
        </div>
    );
};  
export default AffiliateDetailsPage;
