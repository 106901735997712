import React, { useEffect, useState } from "react";
import CommonButton from "../../commonButton/CommonButton";
import api from "../../../../src/api/api";
import { notification } from 'antd';
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const OrderSummary = ({
  selectedColor,
  selectedColorid,
  selectedBundle,
  discountedPrice,
  bookid,
  currentStep,
  images,
  name,
  onPreOrder,
  handleSubmit,
}) => {
  const [affiliateCode, setAffiliateCode] = useState("");
  const [finalDiscountedPrice, setFinalDiscountedPrice] = useState(discountedPrice); 
  const [isAffiliateVerified, setIsAffiliateVerified] = useState(false);
  const [affiliateId, setAffiliateId] = useState(null);
  const [affiliateDiscount, setAffiliateDiscount] = useState(0); 

  const id = Cookies.get("id");

  const navigate = useNavigate();

  const handleAffiliateVerification = async () => {
    try {
      const response = await api.get(`/affiliate/getAffiliateByCode/${affiliateCode}`);
      const affiliateData = response.data.data;

      if (affiliateData) {
        const newDiscountedPrice = discountedPrice - affiliateData.discount;
        setAffiliateDiscount(affiliateData.discount); 
        setFinalDiscountedPrice(newDiscountedPrice > 0 ? newDiscountedPrice : 0);
        setIsAffiliateVerified(true);
        setAffiliateId(affiliateData.id);
        notification.success({ message: "Coupon code verified successfully!" });
      } else {
        notification.error({ message: "Invalid Coupon code. Please correct the code and try again." });
        setFinalDiscountedPrice(discountedPrice); 
        setIsAffiliateVerified(false);
        setAffiliateId(null);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        notification.error({ message: "Invalid Coupon code. Please correct the code and try again." });
      } else {
        console.error("Error verifying affiliate code:", error);
        notification.error({ message: "Error verifying Coupon code. Please try again." });
      }
    }
  };

  const handleRemoveAffiliateCode = () => {
    setAffiliateCode("");
    setFinalDiscountedPrice(discountedPrice); 
    setIsAffiliateVerified(false); 
    setAffiliateId(null);
    setAffiliateDiscount(0);
  };

  useEffect(() => {
    let newDiscountedPrice = discountedPrice;
      
    if (isAffiliateVerified) {
      newDiscountedPrice -= affiliateDiscount;
      if (newDiscountedPrice < 0) newDiscountedPrice = 0; 
    }
    setFinalDiscountedPrice(newDiscountedPrice);
  }, [selectedBundle,  discountedPrice, isAffiliateVerified, affiliateDiscount]);

  const handlePayment = async () => {
    let productColorId = selectedColorid || 1;
    if (selectedColor === "gray") {
      productColorId = 1;
    } else if (selectedColor === "black") {
      productColorId = 2;
    }
    try {
      const amountToPay = finalDiscountedPrice ? finalDiscountedPrice : discountedPrice;
      const orderResponse = await api.post(
        `/orders/createOrder`,
        {
          user_id: id,
          book_id: bookid,
          affiliate_id: affiliateId,
          products_id: productColorId,
          amount: amountToPay
        }
      );

      const razorpayOrder = orderResponse.data.data.responseOrder;
      if (!razorpayOrder) {
        throw new Error('razorpayOrder is undefined');
      }
      if (razorpayOrder.amount === undefined) {
        throw new Error('razorpayOrder.amount is undefined');
      }

      const options = {
        key: process.env.RAZORPAY_KEY_ID,
        amount: razorpayOrder.amount,
        currency: "INR",
        name: name,
        description: "Purchase",
        image: images,
        order_id: razorpayOrder.razorpay_order_id,
        method: {
          upi: true,
          netbanking: false,
          card: false,
          wallet: false,
          emi: false,
        },
        handler: async function (response) {
          try {
            const verificationResponse = await api.post(`/orders/verifyPayment`, {
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
            });

            if (verificationResponse.data.message === "Payment verified and order updated successfully") {
              notification.success({ message: "Payment Successful" });

              const modalElement = document.getElementById('staticBackdrop');
              if (modalElement) {
                const modal = window.bootstrap.Modal.getInstance(modalElement);
                if (modal) {
                  modal.hide(); 
                } else {
                  modal.show();
                }
              }
              navigate("/thank-you");
              window.location.reload('');
            } else {
              notification.error({ message: "Payment verification failed." });
              navigate("/payment-failed");
            }
          } catch (error) {
            console.error("Error verifying payment:", error);
            notification.error({ message: "Payment verification failed." });
          }
        },
        prefill: {
          name: "Version-Next",
          email: "versionnext123.com",
          contact: "9999999999",
        },
        notes: {
          address: "Sakinaka",
        },
        theme: {
          color: "#3399cc",
        },
      };
      if (window.Razorpay) {
        const razorpay = new window.Razorpay(options);
        razorpay.open();
      } else {
        console.error("Razorpay script not loaded.");
        notification.error({ message: "Razorpay script not loaded." });
      }
    } catch (error) {
      console.error("Error during payment:", error);
      notification.error({ message: "Something went wrong with the payment." });
    }
  }; 
  return (
    <div className="order-summary">
      <div className="leftBoxWp">
        <div className="finalProductImgWRap">
          <img src={images} alt="" className="img-fluid" width={114} height={49} />
        </div>
        <div className="nameColrWrap">
          <h2>{name}</h2>
          <p>
            <span>Color:</span> {selectedColor}
          </p>
        </div>
      </div>
      <div className="midBoxWp">
        <h2>Course</h2>
        <p>{selectedBundle}</p>
      </div>

      <div>
        <h2>Discount Code</h2>
        <p>
          <input
            type="text"
            value={affiliateCode}
            onChange={(e) => setAffiliateCode(e.target.value)}
            className="order_affiliate"
            disabled={isAffiliateVerified} 
          />
          {!isAffiliateVerified ? (
            <button
              onClick={handleAffiliateVerification}
              className="verify-button"
              style={{ marginLeft: "10px", padding: "7px 10px", cursor: "pointer", borderRadius: "10px" }}
            >  
              Verify
            </button>
          ) : (
            <button
              onClick={handleRemoveAffiliateCode}
              style={{ marginLeft: "10px", padding: "7px 10px", cursor: "pointer", borderRadius: "10px", backgroundColor:"red"}}
            >
              Remove
            </button>
          )}
        </p>
      </div>
      <div className="rightBoxWp">
        {finalDiscountedPrice < discountedPrice ? (
          <>
            <h3>
              <del>₹{discountedPrice}</del>
            </h3> 
            <h3 className="pricing">₹{finalDiscountedPrice}</h3>
          </>
        ) : (
          <h3 className="pricing">₹{discountedPrice}</h3>
        )}
        {currentStep === 2 ? (
          <form onSubmit={handleSubmit(onPreOrder)}>
            <CommonButton buttonText="Checkout Now" type="submit" onClick={handlePayment} />
          </form>
        ) : (
          <CommonButton buttonText="Pre Order Now" onClick={onPreOrder} type="button" />
        )}
      </div>
    </div>
  );
};
export default OrderSummary;