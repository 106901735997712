import React from "react";
import Carousel from "react-bootstrap/Carousel";
import bg1 from '../../assets/images/slide1-bg1.webp';
import bg2 from '../../assets/images/slide2-bg-1.jpg';
import bg3 from '../../assets/images/slide3-bg-1.webp';
import man1 from '../../assets/images/solaris-performance-video.png';
import clouds from '../../assets/images/clouds.png';
import birds from '../../assets/images/birds.png';
import rain from '../../assets/images/rainbow2.png';

function PurePerformance() {
    return (
        <>
            <Carousel>
                {/* Slide 1 */}
                <Carousel.Item className="carousel-container">
                    <div className="carousel-container-slider1-clouds">
                        <img src={birds} alt="Birds flying" />
                    </div>
                    <img src={bg3} alt="Slide 1: A beautiful scenery" className="d-block w-100 carousel-container-slider1" />
                    <div className="carousel-container-slider1-man">
                        <img src={man1} alt="Man" />
                    </div>
                    <div className="carousel-container-para-lift">
                        <p className="carousel-container-para-liftpara1">All-Day Power, Fast Charging</p>
                    </div>
                </Carousel.Item>

                {/* Slide 2 */}
                <Carousel.Item className="carousel-container">
                    <div className="carousel-container-slider1-clouds">
                        <img src={clouds} alt="Clouds moving" />
                    </div>
                    <img src={bg1} alt="Slide 2: A beautiful scenery" className="d-block w-100 carousel-container-slider1" />
                    <div className="carousel-container-slider1-man">
                        <img src={man1} alt="Man" />
                    </div>
                    <div className="carousel-container-para-lift">
                        <p className="carousel-container-para-liftpara1">Travel Lighter, Travel Smarter</p>
                    </div>
                </Carousel.Item>

                {/* Slide 3 */}
                <Carousel.Item className="carousel-container">
                    <div className="carousel-container-slider1-clouds">
                        <img src={rain} alt="Rainbow" />
                    </div>
                    <img src={bg2} alt="Slide 3: A beautiful scenery" className="d-block w-100 carousel-container-slider1" />
                    <div className="carousel-container-slider1-man">
                        <img src={man1} alt="Man" />
                    </div>
                    <div className="carousel-container-para-lift">
                        <p className="carousel-container-para-liftpara1">Immersive Display, Lasting Power</p>
                    </div>
                </Carousel.Item>
            </Carousel>
        </>
    );
}

export default PurePerformance;
