import React, { useEffect, useState } from 'react';
import api from '../../api/api';
import { useParams } from 'react-router-dom';

const AffiliateView = () => {
    const { id } = useParams();
    const [affiliate, setAffiliate] = useState(null);
    const [commissionSlab, setCommissionSlab] = useState(null);
 
       
    
    useEffect(() => {
        const fetchAffiliate = async () => {
            try {
                const { data } = await api.get(`/affiliate/getAffiliateById/${id}`);
                setAffiliate(data.data);
                
                const { data: slabData } = await api.get(`/affiliate/calculateCommissionSlabForAllAffiliates`);
                const slab = slabData.find(s => s.affiliateId === id);
                setCommissionSlab(slab);
            } catch (error) {
                console.error('Error fetching affiliate:', error);
            }
        };
        fetchAffiliate();
    }, [id]);

    if (!affiliate) return <div>Loading...</div>;
    return (
        <div>
            <h2>Affiliate Details</h2>
            <p>Name: {affiliate.name}</p>
            <p>Email: {affiliate.email}</p>
            <p>Phone: {affiliate.phone}</p>
            <p>Address: {affiliate.address}</p>
            <h3>Commission Slab</h3>
            <p>Current Slab: {commissionSlab ? commissionSlab.currentSlab : 'N/A'}</p>
            <p>Total Unpaid Commission: {commissionSlab ? commissionSlab.totalUnpaidCommission : '0'}</p>
        </div>
    );
};

export default AffiliateView;
