import React, { useState, useEffect } from 'react';
import api from '../api/api';
import Modal from 'react-modal'; 
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { GrView } from "react-icons/gr";

const AdminOrdersPage = () => {
    const [orders, setOrders] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [editingOrderId, setEditingOrderId] = useState(null);
    const [updatedData, setUpdatedData] = useState({ user_id: '', products_id: '', delivery_date_laptop: '', book_id: '', amount: '' });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [viewOrderData, setViewOrderData] = useState(null);

    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState();  
    const [totalOrders, setTotalOrders] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const fetchOrders = async (page, limit) => {
        try {
            const { data } = await api.get(`/orders/getAllOrders`, {
                params: { page, limit }
            });
            
            setOrders(data.data || []); 
            setTotalOrders(data.totalOrders || 0); 
            setTotalPages(data.totalPages || 1); 
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    };

    useEffect(() => {
        fetchOrders(currentPage, itemsPerPage);
    }, [currentPage, itemsPerPage]);

    const openEditModal = (order) => {
        setEditingOrderId(order.id);
        setUpdatedData({ 
            user_id: order.user_id, 
            products_id: order.products_id, 
            delivery_date_laptop: order.delivery_date_laptop, 
            book_id: order.book_id, 
            amount: order.amount 
        });
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setEditingOrderId(null);
        setUpdatedData({ user_id: '', products_id: '', delivery_date_laptop: '', book_id: '', amount: '' });
    };

    const handleSearch = async () => {
        if (searchQuery.trim()) {
            try {
                const { data } = await api.get(`/orders/searchOrders`, {
                    params: { query: searchQuery }
                });
                setOrders(data.data || []); 
                setTotalOrders(data.totalOrders || 0); 
                setTotalPages(data.totalPages || 1); 
                setCurrentPage(1); 
            } catch (error) {
                console.error('Error searching orders:', error);
            }
        } else {
            fetchOrders(currentPage, itemsPerPage);
        }
    };

    const handleEdit = async () => {
        try {
            await api.put(`/orders/editOrder/${editingOrderId}`, updatedData);
            setOrders((prev) =>
                prev.map((order) =>
                    order.id === editingOrderId
                        ? { ...order, ...updatedData }
                        : order
                )
            );
            closeModal();
        } catch (error) {
            console.error('Error editing order:', error);
        }
    };

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm("Would you like to delete this order? Yes / No");
        if (confirmDelete) {
            try {
                await api.delete(`/orders/deleteOrder/${id}`);
                fetchOrders(currentPage, itemsPerPage);
            } catch (error) {
                console.error('Error deleting order:', error);
            }
        } else {
            alert("Order deletion canceled");
        }
    };

    const handleView = (id) => {
        const selectedOrder = orders.find(order => order.id === id);
        setViewOrderData(selectedOrder); 
        setViewModalOpen(true); 
    };

    const closeViewModal = () => {
        setViewModalOpen(false);
        setViewOrderData(null); 
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <div className="dashboard-container orderdashboarddetails">
            <h1>Orders Dashboard</h1>

            <div className="search-container">
                <input
                    type="text"
                    placeholder="Search orders"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <button onClick={handleSearch}>Search</button>
            </div>

            <h2>Orders</h2>
            {orders.length === 0 ? (
                <p>No orders found.</p>
            ) : (
                <table className="orders-table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>User ID</th>
                            <th>Products ID</th>
                            <th>Delivery Date</th>
                            <th>Book ID</th>
                            <th>Amount</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map((order) => (
                            <tr key={order.id}>
                                <td>{order.id}</td>
                                <td>{order.user_id}</td>
                                <td>{order.products_id}</td>
                                <td>{order.delivery_date_laptop}</td>
                                <td>{order.book_id}</td>
                                <td>{order.amount}</td>
                                <td>
                                    <FaEdit onClick={() => openEditModal(order)} style={{ fontSize: "18px" }} />
                                    <MdDelete onClick={() => handleDelete(order.id)} style={{ fontSize: "18px" }} />
                                    <GrView onClick={() => handleView(order.id)} style={{ fontSize: "18px" }} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            {/* Pagination Controls */}
            <div className="pagination-controls">
                <button onClick={handlePrevious} disabled={currentPage === 1}>Previous</button>
                <span>Page {currentPage} of {totalPages}</span>
                <button onClick={handleNext} disabled={currentPage === totalPages}>Next</button>

                {/* Items per page dropdown */}
                <select 
                    value={itemsPerPage} 
                    onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
                     className='dropdowndashboard'
                >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>50</option>
                </select>
            </div>

            {/* Edit Order Modal */}
            <Modal isOpen={isModalOpen} onRequestClose={closeModal}>
                <h2>Edit Order</h2>
                <form>
                    <input type="text" value={updatedData.user_id} onChange={(e) => setUpdatedData({ ...updatedData, user_id: e.target.value })} placeholder="User ID" />
                    <input type="text" value={updatedData.products_id} onChange={(e) => setUpdatedData({ ...updatedData, products_id: e.target.value })} placeholder="Products ID" />
                    <input type="text" value={updatedData.delivery_date_laptop} onChange={(e) => setUpdatedData({ ...updatedData, delivery_date_laptop: e.target.value })} placeholder="Delivery Date" />
                    <input type="text" value={updatedData.book_id} onChange={(e) => setUpdatedData({ ...updatedData, book_id: e.target.value })} placeholder="Book ID" />
                    <input type="number" value={updatedData.amount} onChange={(e) => setUpdatedData({ ...updatedData, amount: e.target.value })} placeholder="Amount" />
                    <button type="button" onClick={handleEdit}>Save</button>
                    <button type="button" onClick={closeModal}>Cancel</button>
                </form>
            </Modal>

            {/* View Order Modal */}
            <Modal isOpen={viewModalOpen} onRequestClose={closeViewModal}>
                <h2>View Order</h2>
                {viewOrderData && (
                    <div>
                        <p><strong>ID:</strong> {viewOrderData.id}</p>
                        <p><strong>User ID:</strong> {viewOrderData.user_id}</p>
                        <p><strong>Products ID:</strong> {viewOrderData.products_id}</p>
                        <p><strong>Delivery Date:</strong> {viewOrderData.delivery_date_laptop}</p>
                        <p><strong>Book ID:</strong> {viewOrderData.book_id}</p>
                        <p><strong>Amount:</strong> {viewOrderData.amount}</p>
                    </div>
                )}
                <button onClick={closeViewModal}>Close</button>
            </Modal>
        </div>
    );
};
export default AdminOrdersPage;
