import React from 'react';
import { Formik } from 'formik';
import api from '../../api/api';
import { notification } from 'antd';

const AffiliateBankDetails = ({ affiliateId }) => {
    
    return (
        <div className="login-container">
            <Formik
                initialValues={{ bank_name: '', bankac: '', bankac_reenter: '', ifsc: '', bankproof: null }}
                validate={values => {
                    const errors = {};
                    if (!values.bank_name) {
                        errors.bank_name = 'Required';
                    }
                    if (!values.bankac) {
                        errors.bankac = 'Required';
                    } else if (values.bankac.length !== 16) {
                        errors.bankac = 'Account number must be 16 digits long.';
                    }
                    if (!values.bankac_reenter) {
                        errors.bankac_reenter = 'Required';
                    } else if (values.bankac_reenter !== values.bankac) {
                        errors.bankac_reenter = 'Account numbers do not match.';
                    }
                    if (!values.ifsc) {
                        errors.ifsc = 'Required';
                    }
                    if (!values.bankproof) {
                        errors.bankproof = 'Required';
                    }
                    return errors;
                }}
                onSubmit={async (values, { setSubmitting, setErrors }) => {
                    try {
                        const formData = new FormData();
                        formData.append("bank_name", values.bank_name);
                        formData.append("bankac", values.bankac);
                        formData.append("ifsc", values.ifsc);

                        // Check if bankproof is selected and append it
                        if (values.bankproof) {
                            formData.append("bankproof", values.bankproof);
                        } else {
                            console.warn("No bankproof file selected.");
                        }

                    
                        // Sending the FormData to the server
                        const response = await api.put(`/affiliate/bank-info/${affiliateId}`, formData);
                       
                        notification.success({
                            message: response.data.message || 'Bank details submitted successfully!',
                        });

                    } catch (error) {
                        console.error("Error submitting form:", error);
                        const errorMessage = error.response?.data?.message || "An error occurred while submitting the form. Please try again.";
                        notification.error({
                            message: errorMessage,
                        });
                    } finally {
                        setSubmitting(false);
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                }) => (
                    <form className="login-form" onSubmit={handleSubmit}>
                        <input
                            type="text"
                            name="bank_name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.bank_name}
                            placeholder="Bank Account Name"
                        />
                        {errors.bank_name && touched.bank_name && <div className="error-message">{errors.bank_name}</div>}

                        <input
                            type="text"  
                            name="bankac"
                            onChange={e => {
                                const value = e.target.value.replace(/\D/g, '').slice(0, 16);
                                setFieldValue("bankac", value);
                            }}
                            onBlur={handleBlur}
                            value={values.bankac}
                            placeholder="Account Number"
                        />
                        {errors.bankac && touched.bankac && <div className="error-message">{errors.bankac}</div>}

                        <input
                            type="text"  
                            name="bankac_reenter"
                            onChange={e => {
                                const value = e.target.value.replace(/\D/g, '').slice(0, 16);
                                setFieldValue("bankac_reenter", value);
                            }}
                            onBlur={handleBlur}
                            value={values.bankac_reenter}
                            placeholder="Re-enter Account Number"
                        />
                        {errors.bankac_reenter && touched.bankac_reenter && <div className="error-message">{errors.bankac_reenter}</div>}

                        <input
                            type="text"
                            name="ifsc"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.ifsc}
                            placeholder="IFSC Code"
                        />
                        {errors.ifsc && touched.ifsc && <div className="error-message">{errors.ifsc}</div>}

                        <input
                            type="file"
                            name="bankproof"
                            onChange={(event) => {
                                const file = event.currentTarget.files[0];
                                if (file) {
                                    setFieldValue("bankproof", file);
                                }
                            }}
                            onBlur={handleBlur}
                            placeholder="Upload Bank Proof"
                        />
                        {errors.bankproof && touched.bankproof && <div className="error-message">{errors.bankproof}</div>}

                        <button type="submit" disabled={isSubmitting}>
                            Submit
                        </button>
                        {errors.submit && <div className="error-message">{errors.submit}</div>}
                    </form>
                )}
            </Formik>
        </div>
    );
};

export default AffiliateBankDetails;
