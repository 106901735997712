import React, { useEffect } from 'react';
import { useAuth } from './AuthContext';
import RedirectWithDelay from '../Auth/RedirectWithDelay';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

function ProtectedRoute({ children }) {
    const { isAuthenticated } = useAuth();
    const role = Cookies.get('role'); 
   
    const navigate = useNavigate();

  
    useEffect(() => {
      
        if (!isAuthenticated || role !== '1') {
            navigate('/');
        }
    }, [isAuthenticated, role, navigate]);

  
    if (!role) {
        return <div>Loading...</div>;
    }

    if (isAuthenticated && role === '1') {
        return children;
    }

    return <RedirectWithDelay message="Illegal operation. Redirecting to the homepage..." />;
}

export default ProtectedRoute;
