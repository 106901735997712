import React, { useEffect, useState } from 'react';
import api from '../../api/api';
import { useParams, useNavigate } from 'react-router-dom';

const AffiliateEdit = () => {
    const { id } = useParams();
    const [affiliate, setAffiliate] = useState(null);
    const [updatedData, setUpdatedData] = useState({ name: '', email: '', phone: '', address: '' });
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAffiliate = async () => {
            try {
                const { data } = await api.get(`/affiliate/getAffiliateById/${id}`);
                setAffiliate(data.data);
                setUpdatedData({ 
                    name: data.data.name, 
                    email: data.data.email, 
                    phone: data.data.phone, 
                    address: data.data.address 
                });
            } catch (error) {
                console.error('Error fetching affiliate:', error);
            }
        };
        fetchAffiliate();
    }, [id]);

    const handleEdit = async () => {
        try {
            await api.put(`/affiliate/editAffiliate/${id}`, updatedData);
            navigate('/affiliates');
        } catch (error) {
            console.error('Error editing affiliate:', error);
        }
    };

    if (!affiliate) return <div>Loading...</div>;

    return (
        <div>
            <h2>Edit Affiliate</h2>
            <input
                type="text"
                value={updatedData.name}
                onChange={(e) => setUpdatedData({ ...updatedData, name: e.target.value })}
                placeholder="Name"
            />
            <input
                type="text"
                value={updatedData.email}
                onChange={(e) => setUpdatedData({ ...updatedData, email: e.target.value })}
                placeholder="Email"
            />
            <input
                type="text"
                value={updatedData.phone}
                onChange={(e) => setUpdatedData({ ...updatedData, phone: e.target.value })}
                placeholder="Phone"
            />
            <input
                type="text"
                value={updatedData.address}
                onChange={(e) => setUpdatedData({ ...updatedData, address: e.target.value })}
                placeholder="Address"
            />
            <button onClick={handleEdit}>Save</button>
        </div>
    );
};
export default AffiliateEdit;
