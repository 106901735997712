import React, { useState, useEffect } from 'react';
import api from '../api/api';
import Modal from 'react-modal'; 
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { GrView } from "react-icons/gr";

const AdminAffiliatePaid = () => {
    const [orders, setOrders] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [editingOrderId, setEditingOrderId] = useState(null);
    const [updatedData, setUpdatedData] = useState({ user_id: '', products_id: '', delivery_date_laptop: '', book_id: '', amount: '' });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [viewModalOpen, setViewModalOpen] = useState(false); 
    const [viewOrderData, setViewOrderData] = useState(null); 

    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10); 

    const fetchOrders = async () => {
        try {
            const { data } = await api.get(`/orders/getAllOrders`);
            setOrders(data.data); 
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    };

    useEffect(() => {
        fetchOrders();
    }, []);

    const openEditModal = (order) => {
        setEditingOrderId(order.id);
        setUpdatedData({ 
            user_id: order.user_id, 
            products_id: order.products_id, 
            delivery_date_laptop: order.delivery_date_laptop, 
            book_id: order.book_id, 
            amount: order.amount 
        });
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setEditingOrderId(null);
        setUpdatedData({ user_id: '', products_id: '', delivery_date_laptop: '', book_id: '', amount: '' });
    };

    const handleSearch = async () => {
        if (searchQuery.trim()) {
            try {
                const { data } = await api.get(`/orders/searchOrders`, {
                    params: { query: searchQuery }
                });
                setOrders(data.data);
                setCurrentPage(1); 
            } catch (error) {
                console.error('Error searching orders:', error);
            }
        } else {
            fetchOrders();
        }
    };

    const handleEdit = async () => {
        try {
            await api.put(`/orders/editOrder/${editingOrderId}`, updatedData);
            setOrders((prev) =>
                prev.map((order) =>
                    order.id === editingOrderId
                        ? { ...order, ...updatedData }
                        : order
                )
            );
            closeModal();
        } catch (error) {
            console.error('Error editing order:', error);
        }
    };

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm("Would you like to delete this order? Yes / No");
        if(confirmDelete){
            try {
                await api.delete(`/orders/deleteOrder/${id}`);
                setOrders((prev) => prev.filter((order) => order.id !== id));
            } catch (error) {
                console.error('Error deleting order:', error);
            }
        }else{
            alert("Order deletion canceled");
        }
    };

    const handleView = (id) => {
        const selectedOrder = orders.find(order => order.id === id);
        setViewOrderData(selectedOrder); 
        setViewModalOpen(true); 
    };

    const closeViewModal = () => {
        setViewModalOpen(false);
        setViewOrderData(null); 
    };

    // Pagination Logic
    const indexOfLastOrder = currentPage * itemsPerPage;
    const indexOfFirstOrder = indexOfLastOrder - itemsPerPage;
    const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);
    const totalPages = Math.ceil(orders.length / itemsPerPage);

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <div className="dashboard-container orderdashboarddetails">
            <h1>Affiliate Paid Dashboard</h1>

            <div className="search-container">
                <input
                    type="text"
                    placeholder="Search orders"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <button onClick={handleSearch}>Search</button>
            </div>

            <h2>Affiliate Paid</h2>
            <table className="orders-table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>User ID</th>
                        <th>Products ID</th>
                        <th>Delivery Date</th>
                        <th>Book ID</th>
                        <th>Amount</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {currentOrders.map((order) => (
                        <tr key={order.id}>
                            <td>{order.id}</td>
                            <td>{order.user_id}</td>
                            <td>{order.products_id}</td>
                            <td>{order.delivery_date_laptop}</td>
                            <td>{order.book_id}</td>
                            <td>{order.amount}</td>
                            <td>
                                <FaEdit onClick={() => openEditModal(order)} style={{ fontSize: "18px" }} />
                                <MdDelete onClick={() => handleDelete(order.id)} style={{ fontSize: "18px" }} />
                                <GrView onClick={() => handleView(order.id)} style={{ fontSize: "18px" }} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Pagination Controls */}
            <div className="pagination-controls">
                <button onClick={handlePrevious} disabled={currentPage === 1}>Previous</button>
                <span>Page {currentPage} of {totalPages}</span>
                <button onClick={handleNext} disabled={currentPage === totalPages}>Next</button>
            </div>

            {/* Edit Modal */}
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                className="react-modal-content"
                overlayClassName="react-modal-overlay"
                contentLabel="Edit Order"
            >
                <h2>Edit Order</h2>
                <form>
                    <label>
                        User ID:
                        <input
                            type="text"
                            value={updatedData.user_id}
                            onChange={(e) => setUpdatedData({ ...updatedData, user_id: e.target.value })}
                        />
                    </label>
                    <label>
                        Products ID:
                        <input
                            type="text"
                            value={updatedData.products_id}
                            onChange={(e) => setUpdatedData({ ...updatedData, products_id: e.target.value })}
                        />
                    </label>
                    <label>
                        Delivery Date:
                        <input
                            type="text"
                            value={updatedData.delivery_date_laptop}
                            onChange={(e) => setUpdatedData({ ...updatedData, delivery_date_laptop: e.target.value })}
                        />
                    </label>
                    <label>
                        Book ID:
                        <input
                            type="text"
                            value={updatedData.book_id}
                            onChange={(e) => setUpdatedData({ ...updatedData, book_id: e.target.value })}
                        />
                    </label>
                    <label>
                        Amount:
                        <input
                            type="text"
                            value={updatedData.amount}
                            onChange={(e) => setUpdatedData({ ...updatedData, amount: e.target.value })}
                        />
                    </label>
                    <button type="button" onClick={handleEdit}>Save</button>
                    <button type="button" className="cancel" onClick={closeModal}>Cancel</button>
                </form>
            </Modal>

            {/* View Modal */}
            {viewOrderData && (
                <Modal
                    isOpen={viewModalOpen}
                    onRequestClose={closeViewModal}
                    className="react-modal-content"
                    overlayClassName="react-modal-overlay"
                    contentLabel="View Order"
                >
                    <h2>Order Details</h2>
                    <p><strong>ID:</strong> {viewOrderData.id}</p>
                    <p><strong>User ID:</strong> {viewOrderData.user_id}</p>
                    <p><strong>Products ID:</strong> {viewOrderData.products_id}</p>
                    <p><strong>Delivery Date:</strong> {viewOrderData.delivery_date_laptop}</p>
                    <p><strong>Book ID:</strong> {viewOrderData.book_id}</p>
                    <p><strong>Amount:</strong> {viewOrderData.amount}</p>
                    <button onClick={closeViewModal}>Close</button>
                </Modal>
            )}
        </div>
    );
};
export default AdminAffiliatePaid;
