import { Table } from 'antd';
import { useEffect, useState } from 'react';
import api from '../../api/api';
import Cookies from 'js-cookie';
import { Toaster} from 'react-hot-toast';
import useSaleAlert from '../../pages/ShowNotification';


function MyAccount() {
    const { saleAlert, loading } = useSaleAlert(); 
    const [orders, setOrders] = useState([]);
    const [userName, setUserName] = useState('');

    // Retrieve user ID from cookies

    const user_id = String(Cookies.get('id')); 
    
    
    // Fetch orders based on user ID
    useEffect(() => {
        const fetchOrders = async () => {
            try {
                // Make sure the endpoint includes the user ID
                const response = await api.get(`orders/getUserOrders/${user_id}`);
               
                const { data } = response.data;

                // Set orders and user name (assuming user name is provided in response)
                setOrders(data);
                setUserName(response.data.data[0]);

            } catch (error) {
                console.error("Error fetching orders:", error);
            }
        };

        if (user_id) {
            fetchOrders();
        }
    }, [user_id]);

    // Columns for Orders Table
    const orderColumns = [{
            title: 'Order ID',
            dataIndex: 'order_id',
            key: 'order_id',
        },
        {
            title: 'Digital Course',
            dataIndex: 'book_name',
            key: 'book_name',
        },
        {
            title: 'Download Course',
            dataIndex: 'book_link',
            key: 'book_link',
            render: (book_link) => (
                <a href={book_link} target="_blank" rel="noopener noreferrer">
                    Click here
                </a>
            ),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Order Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => formatDate(createdAt),
        },
    ];

    // Dummy function for formatting date - replace with actual implementation
    const formatDate = (date) => new Date(date).toLocaleDateString();

    return (
        <div className="my-account" style={{ marginTop: "140px", padding: "0 20px" }}>
            <div className="myaccountspan">
                <h2>Hello {userName.user_name}</h2>
                <p>My Orders</p>
            </div>

            <Table
                columns={orderColumns}
                dataSource={orders}
                rowKey="order_id"
                pagination={false}
                bordered
            />
              <Toaster position="bottom-left" reverseOrder={false} />   
        </div>
    );
}

export default MyAccount;