import axios from 'axios';
// Define the base URL directly in the file
const BASE_URL = 'https://client.solarisbook.com';//Change this URL as needed
//const BASE_URL = 'http://localhost:8002'
const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'x-access-key': 'SOL0SANYOG',
    'Content-Type': 'application/json',
  },
});
export default api;
export { BASE_URL };
