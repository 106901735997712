import React, { useEffect, useState } from 'react';
import api from '../api/api';
import Modal from 'react-modal';
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { GrView } from "react-icons/gr";

const AdminUserPage = () => {
    const [users, setUsers] = useState([]);
    const [editingUserId, setEditingUserId] = useState(null);
    const [updatedUserData, setUpdatedUserData] = useState({
        name: '',
        email: '',
        phone: '',
        role: '',
    });
    const [isOpenUserModal, setIsOpenUserModal] = useState(false);
    const [isOpenViewModal, setIsOpenViewModal] = useState(false);
    const [viewUserData, setViewUserData] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage, setUsersPerPage] = useState(10); // Default value set to 10
    const [totalPages, setTotalPages] = useState(1);

    // Fetch users with pagination
    const fetchUsers = async (page = 1, limit = usersPerPage) => {
        try {
            const { data } = await api.get(`/auth/getAllUsers?page=${page}&limit=${limit}`);
            setUsers(data.data); 
            setCurrentPage(data.currentPage); 
            setTotalPages(data.totalPages);
        } catch (error) {
            console.error("Error while getting Users", error);
        }
    };

    useEffect(() => {
        fetchUsers(currentPage, usersPerPage);
    }, [currentPage, usersPerPage]);

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const openEditUserModal = (user) => {
        setEditingUserId(user.id);
        setUpdatedUserData({
            name: user.name,
            email: user.email,
            phone: user.phone,
            role: user.role,
        });
        setIsOpenUserModal(true);
    };

    const closeUserModal = () => {
        setIsOpenUserModal(false);
        setEditingUserId(null);
        setUpdatedUserData({
            name: '',
            email: '',
            phone: '',
            role: '',
        });
    };

    const openViewUserModal = (user) => {
        setViewUserData(user);
        setIsOpenViewModal(true);
    };

    const closeViewUserModal = () => {
        setIsOpenViewModal(false);
        setViewUserData(null);
    };

    const handleEditUser = async () => {
        try {
            await api.put(`/auth/editUser/${editingUserId}`, updatedUserData);
            setUsers(prev => 
                prev.map(user =>
                    user.id === editingUserId ? { ...user, ...updatedUserData } : user
                )
            );
            closeUserModal();
        } catch (error) {
            console.error("Error while editing user", error);
        }
    };

    const handleDeleteUser = async (userId) => {
        const confirmDelete = window.confirm("Would you like to delete this user? Yes or No");
        if (confirmDelete) {
            try {
                await api.delete(`/auth/deleteUser/${userId}`);
                setUsers(prev => prev.filter(user => user.id !== userId));
            } catch (error) {
                console.error("Error while deleting user", error);
            }
        } else {
            alert('User deletion canceled');
        }
    };

    const handleSearch = async () => {
        if (!searchQuery) {
            fetchUsers(); 
            return;
        }

        try {
            const { data } = await api.get(`/auth/searchUsers?query=${searchQuery}`);
            setUsers(data.data); 
        } catch (error) {
            console.error("Error while searching users", error);
        }
    };

    const handleView = (user) => {
        openViewUserModal(user);
    };

    const handlePerPageChange = (e) => {
        setUsersPerPage(parseInt(e.target.value, 10)); 
        setCurrentPage(1); 
    };

    return (
        <div className='dashboard-container'>
            <h1>User Dashboard</h1>
            <div className='search-container'>
                <input
                    type="text"
                    placeholder='Search User'
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <button onClick={handleSearch}>Search</button>
            </div>
            <h2>Users</h2>

            <table className='adminuser-table'>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Create Date</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user) => (
                        <tr key={user.id}>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>{user.phone}</td>
                            <td>{user.createdAt}</td>
                            <td>
                                <FaEdit onClick={() => openEditUserModal(user)} style={{ fontSize: "18px", cursor: 'pointer' }} />
                                <MdDelete onClick={() => handleDeleteUser(user.id)} style={{ fontSize: "18px", cursor: 'pointer' }} />
                                <GrView onClick={() => handleView(user)} style={{ fontSize: "18px", cursor: 'pointer' }} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Pagination Controls */}
            <div className="pagination-controls">
                <button onClick={prevPage} disabled={currentPage === 1}>Previous</button>
                <span>Page {currentPage} of {totalPages}</span>
                <button onClick={nextPage} disabled={currentPage === totalPages}>Next</button>

                <label htmlFor="usersPerPage"></label>
                <select id="usersPerPage" value={usersPerPage} onChange={handlePerPageChange}
                className='dropdowndashboard'
                >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>50</option>
                </select>
            </div>

            {/* Edit User Modal */}
            <Modal
                isOpen={isOpenUserModal}
                onRequestClose={closeUserModal}
                className="react-modal-content"
                overlayClassName="react-modal-overlay"
                contentLabel="Edit User"
            >
                <h2>Edit User</h2>
                <form>
                    <label htmlFor='name'>Name:</label>
                    <input
                        type="text"
                        id='name'
                        value={updatedUserData.name}
                        onChange={(e) =>
                            setUpdatedUserData({ ...updatedUserData, name: e.target.value })
                        }
                    />
                    <label htmlFor='email'>Email:</label>
                    <input
                        type="email"
                        id='email'
                        value={updatedUserData.email}
                        onChange={(e) =>
                            setUpdatedUserData({ ...updatedUserData, email: e.target.value })
                        }
                    />
                    <label htmlFor='phone'>Phone:</label>
                    <input
                        type="tel"
                        id='phone'
                        value={updatedUserData.phone}
                        onChange={(e) =>
                            setUpdatedUserData({ ...updatedUserData, phone: e.target.value })
                        }
                    />
                    <button type="button" onClick={handleEditUser}>Save Changes</button>
                    <button type="button" onClick={closeUserModal}>Cancel</button>
                </form>
            </Modal>

            {/* View User Modal */}
            <Modal
                isOpen={isOpenViewModal}
                onRequestClose={closeViewUserModal}
                className="react-modal-content"
                overlayClassName="react-modal-overlay"
                contentLabel="View User"
            >
                <h2>User Details</h2>
                {viewUserData && (
                    <div>
                        <p><strong>Name:</strong> {viewUserData.name}</p>
                        <p><strong>Email:</strong> {viewUserData.email}</p>
                        <p><strong>Phone:</strong> {viewUserData.phone}</p>
                        <p><strong>Create Date:</strong> {viewUserData.createdAt}</p>
                    </div>
                )}
                <button onClick={closeViewUserModal}>Close</button>
            </Modal>
        </div>
    );
};
export default AdminUserPage;
